.container-inventory {
    color: #fff;
    /* background-image: url("../../../public/img/Inventory/bg_inventory.png"); */
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: center top;
    padding-bottom: 50px;

    & .container-inventorydb {
        width: 55%;
        margin: 0 auto;

    }

    & .container-nft {
        width: 100%;
        position: relative;
        display: inline-block;

        & .bg-btn-profile {
            width: 100%;
        }

        & .container-nft-btn {
            position: absolute;
            width: 100%;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);

            & .container-nft-btn2 {
                display: flex;
                width: 40%;
                justify-content: center;
                margin: auto;
            }

            & hr {
                height: 1px;
                background: linear-gradient(to right, #38383800, #00e0ff, #38383800);
                border: none;
                width: 60%;
            }
        }
    }

    & .container-nft2 {
        width: 55%;
        margin: auto;

        & .txt-nfts {
            font-family: 'Ralewey Bold', 'Helvetica Semibold', 'Arial semibold', sans-serif;
            font-size: 18px;
            width: fit-content;
            padding: 5px 30px;
            border-radius: 30px;
            background-color: rgba(0, 224, 255, 0.25);
        }
    }

    & .hr-inventory {
        height: 1px;
        background: linear-gradient(to right, #38383800, #00e0ff, #38383800);
        border: none;
        width: 30%;
    }
}