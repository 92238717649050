.container-gameSeason {
  margin: auto;
  width: 80%;
  justify-content: center;
  display: flex;
  margin: auto;
  width: 85%;
}

.container-gameSeason section {
  max-width: 30rem;
}

.container-gameSeason section img {
  width: 100%;
}

.container-gameSeason section h2 {
  width: 100%;
  margin: auto;
  text-align: center;
  font-family: "Raleway Regular", "Arial Regular", sans-serif;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}



@media screen and (min-width: 425px) {
}

@media screen and (min-width: 768px) {
}

@media screen and (min-width: 1280px) {
}
