.container-passes {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-bottom: 60px;
    justify-items: center;

    & .container-nft3 p {
        font-family: 'Bebas Neue', 'Rockwell condensed', Impact, sans-serif;
        font-size: 22px;
        margin: 0;
    }

    & .container-nft3 .container-pass {
        position: relative;
        width: 450px;
        overflow: hidden;
        border-radius: 6px;

        & img {
            width: 100%;
            height: 150px;
            object-fit: cover;
            transition: transform 0.3s ease;
            margin: 0;
            object-position: 50% 60%;
        }

        &:hover img {
            transform: scale(1.2);
            filter: drop-shadow(0px 0px 25px rgba(0, 209, 255, 0.3));
        }

        & .hover-txt {
            width: fit-content;
            position: absolute;
            bottom: 0;
            left: 0;
            color: white;
            padding: 100px 10px 15px;
            z-index: 1;
            margin: 0 0;
            text-align: justify;

            & p {
                font-family: 'Raleway Semibold Italic', 'Helvetica Semibold Italic', 'Arial Semibold Italic', sans-serif;
                font-size: 13px;
                margin: 0;
                padding: 0;
                position: relative;
                z-index: 2;
            }
        }

        & .hover-txt::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: rgb(47, 47, 47);
            background: linear-gradient(180deg, rgba(41, 41, 41, 0) 0%, rgba(0, 0, 0, 0.82) 80%);
            z-index: 0;
            opacity: 0.9;
        }
    }

    & div .Disabled {
        filter: grayscale(100%);
    }
}

.btn-pass {
    position: relative;
    font-size: 25px;
    width: 100px;
    top: -10%;
    right: -37%;
    z-index: 1;
}