article {
  color: #fff;
}

article section p.text-ashara {
  font-family: "Raleway Regular", "Arial Regular", sans-serif;
  font-size: 1rem;
  text-align: center;
  margin: 1% 5%;
}

.container-article {
  margin: auto;
  width: 80%;
  justify-content: center;
}

.container-socialNetwork {
  text-align: center;
  padding-top: 30px;
}

.socialNetwork-followUs {
  font-family: "Raleway Semibold", "Helvetica Semibold", "Arial semibold",
    sans-serif;
  font-size: 2rem;
  margin: 0;
  margin-bottom: 3%;
  color: #fff;
}
.container-heroes {
  text-align: center;
  color: #fff;
}

.btn-flotante {
  font-family: "Raleway Regular", "Arial Regular", sans-serif;
  font-weight: bold;
  color: #ffffff;
  border-radius: 50px;
  background-color: #5865f2;
  padding: 12px 10px;
  position: fixed;
  bottom: 20px;
  right: 20px;
  transition: all 300ms ease 0ms;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  z-index: 1;
}
.btn-flotante:hover {
  background-color: #202225;
  box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.3);
  transform: translateY(-7px);
}

.btn-img {
  width: 40px;
}

@media screen and (min-width: 425px) {
  article section p.text-ashara {
    font-size: 1.2rem;
    margin: 2% 10%;
  }
}

@media screen and (min-width: 768px) {
  article section p.text-ashara {
    font-size: 1.25rem;
    text-align: center;
    margin: 2% 6%;
  }

  .container-article {
    display: flex;
    margin: auto;
    width: 85%;
  }
}

@media screen and (min-width: 1280px) {
  article section p.text-ashara {
    font-size: 1.7rem;
    text-align: center;
  }
  .container-article {
    width: 75%;
  }
}
