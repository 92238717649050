.container-games {
  margin: 30px auto;
  width: 90%;
}
.games-con-back {
  padding-bottom: 30px;
}
.games {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.games-img {
  width: 300px;
}
.games-background {
  display: none;
}
.games-title {
  color: #fff;
  font-family: Bebas Neue;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
}

.games-type {
  color: #00e0ff;
  font-family: Raleway Semibold;
  font-size: 0.75rem;
  font-style: italic;
  font-weight: 600;
  line-height: normal;
  margin: 0;
}

.game-description {
  color: #fff;
  font-family: Raleway Regular;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.game-platform {
  color: #fff;
  font-family: Ralewey Bold;
  font-size: 0.75rem;
  font-style: italic;
  font-weight: 700;
  line-height: normal;
}
.game-platform-coniner {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
}

.btn {
  width: 150px;
  height: 25px;
  border-radius: 5px;
  letter-spacing: 2px;
  background-color: #202225;
  padding: 12px 15px;
  transition: all 300ms ease 0ms;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-items: center;
  justify-content: center;
}

.btn img {
  height: 100%;
}

.btn:hover {
  background-color: #666666;
  box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.3);
  transform: translateY(-7px);
}

@media screen and (min-width: 425px) {
  .games-title {
    font-size: 1.5rem;
  }

  .games-type,
  .game-description,
  .game-platform {
    font-size: 1rem;
  }
}
@media screen and (min-width: 768px) {
  .game-platform-coniner {
    align-items: flex-start;
    flex-direction: row;
    gap: 0 10px;
  }
  .hr-movil {
    display: none;
  }
  .container-games {
    margin: 0;
    width: 100%;
  }
  .games-con-back {
    position: relative;
    margin: 20px 0;
  }

  .games-background {
    display: block;
    padding-top: 30px;
    width: 100%;
    height: 350px;
    position: relative;
    object-fit: cover;
  }
  .games {
    flex-direction: row;
    width: 80%;
    margin: 0 auto;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }

  .games-img {
    width: 300px;
  }

  .games section {
    margin-top: 10%;
  }

  .games-title {
    font-size: 2rem;
  }

  .games-type,
  .game-description,
  .game-platform {
    font-size: 1rem;
  }
}

@media screen and (min-width: 1280px) {
  .game-platform-coniner {
    gap: 0 30px;
  }
  .games {
    width: 70%;
  }
  .games-con-back {
    margin: 30px 0;
  }

  .games-background {
    padding-top: 20px;
    height: 100%;
  }
  .games-img {
    width: auto;
    height: 100%;
  }
  .games section {
    margin-top: 10%;
  }

  .games-title {
    font-size: 2.625rem;
  }

  .games-type,
  .game-description,
  .game-platform {
    font-size: 1.375rem;
  }
}

