.RedesSociales {
  display: flex;
  justify-content: center;
  align-items: center;
}

.RedesSociales-followUs {
  font-family: "Raleway Semibold", "Helvetica Semibold", "Arial semibold",
    sans-serif;
  font-size: 16px;
  margin-bottom: 3%;
  color: #666;
}

.RedesSociales-imgLogo {
  font-size: 2em;  
  margin:0.4rem;
}

@media screen and (min-width: 576px) {
  .RedesSociales-followUs {
    font-size: 29px;
    
    margin: 0.5rem 1.5rem;
  }
}

@media screen and (min-width: 768px) {
  .RedesSociales-followUs {
    font-size: 39px;
  }

  .RedesSociales-imgLogo {
    font-size: 2.5em;
    margin: 1rem 2rem;
  }
}

@media screen and (min-width: 1024px) {
  .RedesSociales-imgLogo {
    font-size: 3em;
  }
}

@media screen and (min-width: 1280px) {
  .RedesSociales-followUs {
    font-family: "Raleway Semibold", "Helvetica Semibold", "Arial semibold",
      sans-serif;
    font-size: 22px;
  }

  .RedesSociales-imgLogo {
    font-size: 3.5em;
    margin: 2.5rem;
  }
}
