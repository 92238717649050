.icon-container {
  margin: auto 0;
  padding: 0 0.5em;
}

@media screen and (min-width: 320px) {
  .footer-icon-small {
    display: initial;
  }
  .footer-icon-medium {
    display: none;
  }
  .footer-icon-big {
    display: none;
  }
  .footer-icon-large {
    display: none;
  }
}

@media screen and (min-width: 576px) {
  .footer-icon-small {
    display: none;
  }
  .footer-icon-medium {
    display: initial;
  }
  .footer-icon-big {
    display: none;
  }
  .footer-icon-large {
    display: none;
  }
}

@media screen and (min-width: 768px) {
  .footer-icon-small {
    display: none;
  }
  .footer-icon-medium {
    display: none;
  }
  .footer-icon-big {
    display: initial;
  }
  .footer-icon-large {
    display: none;
  }
  .footer-icon {
    width: 100%;
  }
}

@media screen and (min-width: 1280px) {
  .footer-icon-small {
    display: none;
  }
  .footer-icon-medium {
    display: none;
  }
  .footer-icon-big {
    display: none;
  }
  .footer-icon-large {
    display: initial;
  }
}


