.Hero-mobile-container {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.Hero-marco {
  position: absolute;
  width: 52%;
}

.Hero-marco-desktop,
.Hero-marco2-desktop,
.Hero-lineas {
  display: none;
}

.Hero-photo {
  position: absolute;
  width: 46.3%;
  top: 5%;
  left: 26%;
}

.Hero-name {
  position: relative;
  width: 100%;
  margin-top: 58%;
  display: grid;
  place-items: center;
}

.Hero-marco-text {
  position: relative;
  width: 100%;
  margin-top: -0.2%;
  height: 43px;
  left: 0;
}

.Hero-name p {
  position: absolute;
  font-family: "Bebas Neue", "Rockwell condensed", Impact, sans-serif;
  text-transform: uppercase;
  font-size: 37px;
  margin: 0;
  top: 0;
}

.Hero-description {
  font-family: "Raleway Italic", "Helvetica Italic", "Arial Italic", Arial,
    sans-serif;
  font-size: 22px;
  margin-bottom: 8%;
  text-align: center;
}
.Heroes-container {
  display: flex;
  flex-direction: column;
  margin-top: 60px;
}

@media screen and (min-width: 768px) {
  .Hero {
    width: 40%;
    margin: 0;
  }

  .Heroes-container {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
  }
}
@media screen and (min-width: 1024px) {
  .Hero {
    width: 26%;
    margin: 0;
  }

  .Hero-marco-desktop,
  .Hero-marco2-desktop {
    display: block;
    transition: transform 0.3s linear;
  }

  .Hero-lineas {
    display: block;
    position: absolute;
    width: 58%;
    top: -5%;
    opacity: 0;
    transition: transform 0.3s linear, opacity 0.3s linear;
  }

  .Hero-photo {
    position: absolute;
    width: 48%;
    top: 3.8%;
    filter: grayscale(100%);
    transition: transform 0.3s linear, filter 0.3s linear;
  }

  .Hero:hover .Hero-marco-desktop {
    transform: scale(0.975) rotate(-20deg);
    transition: transform 0.3s linear;
  }

  .Hero:hover .Hero-marco2-desktop {
    transform: scale(1.05) rotate(-20deg);
    transition: transform 0.3s linear;
  }

  .Hero:hover .Hero-photo {
    transform: scale(1.05);
    filter: grayscale(0%);
    transition: transform 0.3s linear, filter 0.3s linear;
  }

  .Hero:hover .Hero-lineas {
    transform: scale(0.975) rotate(-20deg);
    opacity: 1;
    transition: transform 0.3s linear, opacity 0.3s linear;
  }

  .Hero-marco {
    display: none;
  }

  .Hero-marco-desktop {
    position: absolute;
    width: 57%;
    top: -4%;
  }

  .Hero-marco2-desktop {
    position: absolute;
    width: 52%;
  }
}
