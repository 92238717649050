@font-face {
  font-family: "Bebas Neue";
  font-display: swap;
  src: url("/public/fonts/BebasNeue/BebasNeue.otf");
}

@font-face {
  font-family: "Raleway Thin";
  font-display: swap;
  src: url("/public/fonts/Raleway/Raleway-Thin.ttf");
}

@font-face {
  font-family: "Ralewey Bold";
  font-display: swap;
  src: url("/public/fonts/Raleway/Raleway-Bold.ttf");
}

@font-face {
  font-family: "Ralewey ExtraBold";
  font-display: swap;
  src: url("/public/fonts/Raleway/Raleway-ExtraBold.ttf");
}

@font-face {
  font-family: "Ralewey ExtraBoldI talic";
  font-display: swap;
  src: url("/public/fonts/Raleway/Raleway-ExtraBoldItalic.ttf");
}

@font-face {
  font-family: "Raleway Semibold";
  font-display: swap;
  src: url("/public/fonts/Raleway/Raleway-SemiBold.ttf");
}

@font-face {
  font-family: "Raleway Medium";
  font-display: swap;
  src: url("/public/fonts/Raleway/Raleway-Medium.ttf");
}

@font-face {
  font-family: "Raleway Semibold Italic";
  font-display: swap;
  src: url("/public/fonts/Raleway/Raleway-SemiBoldItalic.ttf");
}

@font-face {
  font-family: "Raleway Regular";
  font-display: swap;
  src: url("/public/fonts/Raleway/Raleway-Regular.ttf");
}

@font-face {
  font-family: "Raleway Italic";
  font-display: swap;
  src: url("/public/fonts/Raleway/Raleway-Italic.ttf");
}

@font-face {
  font-family: "Raleway Light Italic";
  font-display: swap;
  font-style: italic;
  src: url("/public/fonts/Raleway/Raleway-LightItalic.ttf");
}

@font-face {
  font-family: "Montserrat Semibold Italic";
  font-display: swap;
  src: url("/public/fonts/Montserrat/Montserrat-SemiBoldItalic.ttf");
}

@font-face {
  font-family: "Helvetica Semibold";
  font-display: swap;
  font-weight: 600;
  src: local("Helvetica");
}

@font-face {
  font-family: "Arial Semibold";
  font-display: swap;
  font-weight: 600;
  src: local("Arial");
}

@font-face {
  font-family: "Helvetica Italic";
  font-display: swap;
  font-style: italic;
  src: local("Helvetica");
}

@font-face {
  font-family: "Arial Italic";
  font-display: swap;
  font-style: italic;
  src: local("Arial");
}

@font-face {
  font-family: "Helvetica Semibold Italic";
  font-display: swap;
  font-style: italic;
  font-weight: 600;
  src: local("Helvetica");
}

@font-face {
  font-family: "Arial Semibold Italic";
  font-display: swap;
  font-style: italic;
  font-weight: 600;
  src: local("Arial");
}

@font-face {
  font-family: "Arial Regular";
  font-display: swap;
  letter-spacing: 0.3px;
  src: local("Arial");
}

body {
  margin: 0;
  padding: 0;
  background: #000;
}

a,
a:link,
a:visited,
a:hover,
a:active {
  color: white;
}

hr {
  height: 1px;
  background: linear-gradient(to right, #38383800, #7e7e7e, #38383800);
  border: none;
}

.button1 {
  display: block;
  text-decoration: none;
  font-family: "Bebas Neue", "Rockwell condensed", Impact, sans-serif;
  font-size: 35px;
  color: #fff;
  background-color: #00b2ff;
  text-align: center;
  border-radius: 8px;
  width: 200px;
  border: none;
  margin: 20px auto;
  padding: 0;
}

.btn-large {
  width: 250px !important;
}

.button1:hover {
  filter: drop-shadow(0px 8px 30px rgba(0, 224, 255, 0.5));
  background-color: #42c6ff;
}

.button1:active {
  background-color: #94dfff;
  filter: drop-shadow(0px 8px 30px rgb(148, 223, 255, 0.5));
}

.button1-desactive {
  font-family: "Bebas Neue", "Rockwell condensed", Impact, sans-serif;
  font-size: 35px;
  color: #fff;
  background-color: #373737;
  text-align: center;
  border-radius: 8px;
  width: 200px;
  border: none;
  margin: 10px auto;
  padding: 0;
}

.btn-form {
  display: block;
  text-decoration: none;
  font-family: "Bebas Neue", "Rockwell condensed", Impact, sans-serif;
  font-size: 35px;
  color: #fff;
  background-color: #00B2FF;
  text-align: center;
  border-radius: 8px;
  width: 200px;
  border: none;
  margin: 20px auto;
  padding: 0;
}

.btn-form:hover {
  filter: drop-shadow(0px 8px 30px rgba(0, 224, 255, 0.5));
  background-color: #42c6ff;
  cursor: pointer;
}

.btn-form:disabled {
  color: #fff;
  background-color: #373737;
  cursor: auto
}

.button2 {
  display: block;
  text-decoration: none;
  font-family: "Bebas Neue", "Rockwell condensed", Impact, sans-serif;
  font-size: 35px;
  color: #fff;
  background-color: #ffb039;
  text-align: center;
  border-radius: 8px;
  width: 188px;
  border: none;
  margin: 0 auto;
  padding: 0;
}

.button2:hover {
  filter: drop-shadow(0px 8px 30px rgba(0, 224, 255, 0.5));
  background-color: #42c6ff;
}

.button3 {
  display: block;
  text-decoration: none;
  font-family: "Bebas Neue", "Rockwell condensed", Impact, sans-serif;
  font-size: 35px;
  color: #fff;
  background-color: #373737;
  text-align: center;
  border-radius: 8px;
  width: 225px;
  border: none;
  margin: 0 auto;
  padding: 0;
}

.button3:hover {
  filter: drop-shadow(0px 8px 15px rgba(0, 224, 255, 0.1));
  background-color: #208CAF;
  cursor: pointer;
}

.button3.active {
  background-color: #00B2FF;
  filter: drop-shadow(0px 8px 15px rgba(0, 224, 255, 0.3));
  cursor: pointer;
}

p .italic {
  font-family: "Raleway Semibold Italic", "Helvetica Semibold Italic",
    "Arial Semibold Italic", sans-serif;
}

select {
  appearance: none;
  border-radius: 5px;
  width: 180px;
  height: 40px;
  margin-top: 10px;
  font-family: "Ralewey Bold", "Helvetica Semibold", "Arial semibold",
    sans-serif;
  font-size: 18px;
  color: #00e0ff;
  text-align: center;
  border-top: 0px;
  border-color: rgba(0, 149, 182, 0.6);
  background: transparent;
  background-image: url("../public/img/Quest/arrow.png");
  background-repeat: no-repeat, repeat;
  background-position: right 0.5em top 50%;
  background-size: auto auto;
}

select.select-mision {
  width: 290px;
}

select::-ms-expand {
  display: none;
}

select:focus {
  color: white;
  background: black;
  background-image: url("../public/img/Quest/arrow.png");
  background-repeat: no-repeat, repeat;
  background-position: right 0.5em top 50%;
  background-size: auto auto;
  outline: none;
}

select option {
  font-family: "Raleway Medium", Helvetica, Arial, sans-serif;
  margin: 50px 0 !important;
}

.txt-form {
  font-family: "Raleway Italic", "Helvetica Italic", "Arial Italic", Arial,
    sans-serif;
  font-size: 16px;
  color: #ff4a4a;
  margin: 5PX;
}

.txt-login,
.txt-signup {
  font-family: "Raleway Semibold", "Helvetica Semibold", "Arial semibold",
    sans-serif;
  font-size: 16px;
  margin-top: 40px;
}

.txt-password {
  font-family: "Raleway Semibold", "Helvetica Semibold", "Arial semibold",
    sans-serif;
  font-size: 16px;
  margin: -10px 0 30px;
}

.txt-login a,
.txt-signup a,
.txt-password a {
  font-family: "Raleway Semibold Italic", "Helvetica Semibold Italic",
    "Arial Semibold Italic", sans-serif;
  color: #00e0ff !important;
  text-decoration: none;
}

/* Se cargan todas las fuentes en un unico lugar, para que no se repitan font-faces en los estilos de los componentes
   Las secuencias de fallback para cada fuente se dejan a continuacion:

  font-family: 'Bebas Neue', 'Rockwell condensed', Impact, sans-serif;
  font-family: 'Raleway Thin', Helvetica, Arial, sans-serif;
  font-family: 'Raleway Semibold', 'Helvetica Semibold', 'Arial semibold', sans-serif;
  font-family: 'Raleway Italic', 'Helvetica Italic', 'Arial Italic', Arial, sans-serif;
  font-family: 'Raleway Medium', Helvetica, Arial, sans-serif;
  font-family: 'Raleway Semibold Italic', 'Helvetica Semibold Italic', 'Arial Semibold Italic', sans-serif;
  font-family: 'Raleway Regular', 'Arial Regular', sans-serif;
  font-family: 'Raleway Light Italic', 'Helvetica Italic', 'Arial Italic', Arial, sans-serif;
  font-family: 'Ralewey Bold', 'Helvetica Semibold', 'Arial semibold', sans-serif;
  font-family: 'Montserrat Semibold Italic',  'Arial Semibold Italic', sans-serif;
  font-family: 'Ralewey ExtraBold', 'Helvetica Semibold', 'Arial semibold', sans-serif;
    font-family: "Ralewey ExtraBoldI talic", 'Ralewey ExtraBold', 'Helvetica Semibold', 'Arial semibold', sans-serif;

*/