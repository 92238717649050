footer {
  flex: none;
  margin-left: -2px;
}

.text1 {
  font-family: "Bebas Neue", "Rockwell condensed", Impact, sans-serif;
  text-transform: uppercase;
  font-size: 1em;
  margin: 0.5em;
  letter-spacing: 3px;
}

.text2 {
  font-family: "Raleway Thin", Helvetica, Arial, sans-serif;
  color: white;
  text-decoration: none;
  font-size: 0.7em;
  margin: 0 auto;
}

footer {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
}

.footer-back {
  width: 100%;
}

.fa-2x {
  padding-right: 5%;
}

.footer-grid-container {
  display: grid;
  color: white;
  box-sizing: border-box;
  position: absolute;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
  font-size: 13px;
  grid-template-columns: repeat(4, 1fr);
  grid-template-areas:
    "logo filler filler filler"
    "logo emailme emailme emailme"
    "logo callme callme callme"
    "logo closing closing .";
}

.footer-grid-container .logo {
  grid-area: logo;
  margin: auto;
}

.filler {
  grid-area: filler;
  margin: auto;
  height: 32px;
}

.emailme {
  grid-area: emailme;
  display: inline-flex;
  justify-content: flex-end;
}

.callme {
  grid-area: callme;
  display: inline-flex;
  justify-content: flex-end;
}

.closing {
  grid-area: closing;
  display: flex;
  flex-direction: column;
  margin: auto;
  align-items: center;
  justify-content: center;
}

.privacy {
  display: inline-flex;
  align-items: center;
}

@media screen and (min-width: 320px) {
  .footer-back-small {
    display: initial;
  }
  .footer-back-medium {
    display: none;
  }
  .footer-back-big {
    display: none;
  }
  .footer-back-large {
    display: none;
  }
  .filler {
    height: 16px;
  }
  .text1 {
    letter-spacing: 2px;
  }
}

@media screen and (min-width: 576px) {
  .footer-back-small {
    display: none;
  }
  .footer-back-medium {
    display: initial;
  }
  .footer-back-big {
    display: none;
  }
  .footer-back-large {
    display: none;
  }
  .footer-grid-container {
    font-size: 25px;
  }
  .text1 {
    letter-spacing: 3px;
  }
}

@media screen and (min-width: 768px) {
  .footer-grid-container {
    font-size: 31px;
    padding: 0 2%;
  }
  .filler {
    padding: 2% 0;
  }
  .footer-back-small {
    display: none;
  }
  .footer-back-medium {
    display: none;
  }
  .footer-back-big {
    display: initial;
  }
  .footer-back-large {
    display: none;
  }
}

@media screen and (min-width: 1280px) {
  .footer-back-small {
    display: none;
  }
  .footer-back-medium {
    display: none;
  }
  .footer-back-big {
    display: none;
  }
  .footer-back-large {
    display: initial;
  }
  .footer-grid-container {
    padding: 0 10%;
  }
  .filler {
    padding: 0;
  }
  .text1 {
    margin: 0.5em;
  }
  .logo {
    margin: 0;
  }
  .fa-2x {
    padding-right: 0%;
  }
}
