.continer-milestone {
  position: relative;
  display: flex;
  justify-content: center;
}

.continer-flag,
.continer-flagDesactive {
  display: block;
  position: relative;
  display: flex;
  justify-content: center;
  z-index: 1;
}

.continer-flag img {
  position: absolute;
  width: fit-content;
  margin-top: -78px;
}


.continer-flagDesactive img {
  position: absolute;
  width: fit-content;
  margin-top: -58px;
}

.continer-flag p {
  position: absolute;
  margin: 0;
  font-family: "Bebas Neue", "Rockwell condensed", Impact, sans-serif;
  font-size: 30px;
  color: #854400;
  z-index: 1;
  margin-top: -35px;
}

.continer-flagDesactive p {
  position: absolute;
  margin: 0;
  font-family: "Bebas Neue", "Rockwell condensed", Impact, sans-serif;
  font-size: 30px;
  color: #fff;
  z-index: 1;
  margin-top: -35px;
}


.continer-sand {
  position: absolute;
  margin-top: 35px;
  margin-left: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
}

.continer-sand p {
  font-family: "Ralewey Bold", "Helvetica Semibold", "Arial semibold",
    sans-serif;
  font-size: 16px;
}

progress[value] {
  -webkit-appearance: none;
  appearance: none;
  border-radius: 0 8px 0 8px;
  height: 8px;
  width: 106px;
  margin-top: 20px;
}

progress[value]::-webkit-progress-bar {
  background: black;
  box-shadow: 0px 0px 16px rgb(0, 163, 255, 0.9);
}

progress[value]::-webkit-progress-value {
  background: rgb(0, 88, 125);
  background: linear-gradient(90deg,
      rgba(0, 88, 125, 1) 0%,
      rgba(0, 224, 255, 1) 100%);
  border-radius: 0 8px 8px 0;
}