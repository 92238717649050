.container-medals::-webkit-scrollbar {
    width: 5px;
    height: 136px;
}

.container-medals::-webkit-scrollbar-track {
    background: transparent;
}

.container-medals::-webkit-scrollbar-thumb {
    background: #D9D9D9;
    border-radius: 6px;
}

.container-medals {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 50px 40px;
    justify-items: center;
    height: 700px;
    overflow-y: scroll;
    overflow-x: hidden;

    & .container-medals1 {
        position: relative;
        border-radius: 11px;
        width: 100%;
        box-shadow: 0px 0px 25px rgba(0, 209, 255, 0.3);
        width: 95%;
        margin: auto;

        .container-inv-medal {
            display: grid;
            grid-template-columns: 1fr 3fr;
            justify-items: center;
            align-items: center;
            background: linear-gradient(270deg, rgba(0, 0, 0, 1) 0%, rgba(18, 34, 58, 1) 100%);
            border-radius: 11px;
            margin: 2px;
            padding: 7px 70px 7px 0;
            height: 100%;
            text-align: justify;

            & .img-medal {
                width: 100px;
                height: 100px;
                clip-path: circle(45%);
                filter: drop-shadow(0px 8px 30px rgba(0, 224, 255, 0.5));
            }

            & div .txt-medal {
                font-family: 'Ralewey Bold', 'Helvetica Semibold', 'Arial semibold', sans-serif;
                font-size: 18px;
                margin: 0;
                padding: 0;
            }

            & div .txt-medal2 {
                font-family: 'Ralewey Bold', 'Helvetica Semibold', 'Arial semibold', sans-serif;
                font-size: 18px;
                margin: 0;
                padding: 0;
            }

            & div .txt-medal-desc {
                font-family: 'Raleway Semibold Italic', 'Helvetica Semibold Italic', 'Arial Semibold Italic', sans-serif;
                font-size: 13px;
            }

            & div hr {
                background: linear-gradient(90deg, rgba(0, 224, 255, 1) 0%, rgba(56, 56, 56, 0) 100%);
                opacity: 0.5;
            }
        }
    }

    & .container-medals1::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: 11px;
        padding: 2px;
        -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
        mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
        -webkit-mask-composite: xor;
        mask-composite: exclude;
        z-index: -1;
        box-shadow: 0px 0px 26px rgba(0, 209, 255, 0.33);
        background: linear-gradient(180deg, rgba(59, 207, 240, 1) 0%, rgba(0, 209, 255, 0.17) 81%);
    }

    & .container-medals1:hover {
        box-shadow: 0px 0px 26px rgba(0, 209, 255, 0.5);
        height: 100%;

        & .container-inv-medal div hr {
            opacity: 1;
        }
    }

    & .Disabled {
        filter: grayscale(100%);
    }

}