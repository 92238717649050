.modal {
  position: relative;
  display: flex;
  width: 100%;
  border-radius: 10px;
  z-index: 10;

  & img {
    border-radius: 10px;
    width: 45%;
    margin: -10px auto 0;
    box-shadow: 0px 0px 26px rgba(0, 209, 255, 0.33);
  }
}