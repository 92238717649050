.input-icon-container {
  position: relative;
  display: inline-block;
}

.input-icon {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-100%);
  width: 17px;
  height: 17px;
}

.input-with-icon {
  padding-left: 40px;
  padding-right: 10px;
  height: 40px;
  width: 420px;
  font-size: 16px;
}