.container-leaderboard {
    position: relative;
    width: 100%;
    color: white;
    background-image: url("../../../public/img/Leaderboard/bg_leaderboard.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center top;
}

.container-leaderboard img.bg-leaderboard {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    z-index: -1;
}

.container-leaderboard2 {
    position: relative;
    width: 100%;
    padding-bottom: 30px;

    & section.container-sandPool h2.txt-invite {
        font-size: 25px;
        margin: -50px 0 40px;
    }

    & section.container-sandPool .txt-totalPlayer {
        font-family: 'Ralewey Bold', 'Helvetica Semibold', 'Arial semibold', sans-serif;
        color: #FFD540;
        font-size: 20px;
        position: absolute;
        left: 14%;
        top: 5%
    }
}

.continer-row-title {
    display: grid;
    grid-template-columns: 2fr 1fr 5fr 3fr 3fr 3fr 3fr 2fr;
    justify-items: center;
    align-items: center;
    width: 75%;
    margin: 0 auto;

    & h2 {
        font-family: 'Ralewey ExtraBold', 'Helvetica Semibold', 'Arial semibold', sans-serif;
        font-size: 18px;
        margin: 0;
        text-align: center;
    }
}

hr.continer-row-title {
    height: 1px;
    background: linear-gradient(90deg, rgba(0, 224, 255, 0) 0%, rgba(0, 224, 255, 1) 30%, rgba(0, 224, 255, 0.89) 51%, rgba(0, 224, 255, 0) 100%);
    border: none;
    width: 60%;
    margin: 9px auto;
}

.container-leaderboard-table {
    width: 75%;
    height: 455px;
    margin: 0 auto;
    padding: 0 10px 20px;
    overflow-y: scroll;
    overflow-x: hidden;

    & .continer-row {
        position: relative;
        display: grid;
        grid-template-columns: 2fr 1fr 5fr 3fr 3fr 3fr 3fr 2fr;
        gap: 9px 0px;
        background-color: rgba(217, 217, 217, 0.1);
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        justify-items: center;
        align-items: center;
        border-radius: 10px;
        height: 38px;

        & img {
            width: auto;
        }

        .img-top {
            position: absolute;
            top: 0%;
            left: -10px;
            width: auto;
        }

        .top1 {
            filter: drop-shadow(0px 0px 20px rgba(255, 199, 0, 0.58));
        }

        .top2 {
            filter: drop-shadow(0px 0px 20px rgba(255, 255, 255, 0.2));
        }
    }

    .continer-row-user {
        position: relative;
        display: grid;
        grid-template-columns: 2fr 1fr 5fr 3fr 3fr 3fr 3fr;
        gap: 9px 0px;
        background-color: rgba(255, 160, 91, 0.28);
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        justify-items: center;
        align-items: center;
        border-radius: 10px;
        width: 98%;
        margin: 0 0 0 auto;
        height: 38px;

        & img {
            width: auto;
        }

        .user-arrow {
            position: absolute;
            top: 0%;
            left: -14px;
            transform: translate(-50%, 50%);
            width: auto;
            animation: moveX 1s infinite alternate;
        }
    }

    p.number {
        font-family: 'Raleway Medium', Helvetica, Arial, sans-serif;
        font-size: 18px;
        margin: 0;
    }

    p.number-top {
        font-family: 'Ralewey ExtraBold', 'Helvetica Semibold', 'Arial semibold', sans-serif;
        font-size: 18px;
        margin: 0;
    }

    p.txt-table {
        font-family: 'Raleway Regular', 'Arial Regular', sans-serif;
        font-size: 16px;
        margin: 0;
    }

    p.txt-table-user {
        font-family: 'Raleway Semibold', 'Helvetica Semibold', 'Arial semibold', sans-serif;
        font-size: 16px;
        color: rgba(255, 199, 0, 0.89);
        margin: 0;
    }

    hr {
        height: 1px;
        background: linear-gradient(90deg, rgba(0, 224, 255, 0) 0%, rgba(0, 224, 255, 1) 30%, rgba(0, 224, 255, 0.89) 51%, rgba(0, 224, 255, 0) 100%);
        opacity: 0.5;
        border: none;
        width: 100%;
        margin: 0px auto 9px;
    }

    hr.one {
        background: linear-gradient(90deg, rgba(255, 245, 0, 0) 0%, rgba(255, 184, 0, 1) 30%, rgba(255, 245, 0, 1) 51%, rgba(255, 245, 0, 0) 100%);
    }

    hr.two {
        background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(219, 219, 219, 1) 30%, rgba(255, 255, 255, 0.89) 51%, rgba(255, 255, 255, 0) 100%);
    }

    hr.tree {
        background: linear-gradient(90deg, rgba(214, 170, 56, 0) 0%, rgba(147, 77, 5, 1) 26%, rgba(147, 77, 5, 1) 46%, rgba(214, 170, 56, 0) 100%);
    }
}

.container-leaderboard-table::-webkit-scrollbar {
    width: 5px;
    height: 136px;
}

.container-leaderboard-table::-webkit-scrollbar-track {
    background: transparent;
}

.container-leaderboard-table::-webkit-scrollbar-thumb {
    background: #D9D9D9;
    border-radius: 6px;
}

@keyframes moveX {
    0% {
        transform: translate(-50%, 50%) translateX(0);
    }

    100% {
        transform: translate(-50%, 50%) translateX(4px);
    }
}