.AboutUs {
  color: #ffffff;
}

.hero {
  position: relative;
  display: block;
  padding: 30% 0%;
}

.hero p {
  position: inherit;
  font-family: "Bebas Neue", "Rockwell condensed", Impact, sans-serif;
  text-transform: uppercase;
  color: #000000;
  font-size: 6.25em;
  margin: 0;
  z-index: 1;
}

.hero-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 0;
}

.hero-img-medium {
  display: none;
}

.team-photo-img {
  width: 100%;
  display: flex;
  margin: -14% 0;
}

.team-photo-img-medium {
  display: none;
}

.aboutUs-description {
  padding: 25% 5% 1%;
}

.description-title {
  font-family: "Bebas Neue", "Rockwell condensed", Impact, sans-serif;
  text-transform: uppercase;
  font-size: 4em;
  margin: 0;
  display: none;
}

.description-details {
  font-family: "Raleway Medium", Helvetica, Arial, sans-serif;
  font-size: 12px;
  letter-spacing: -10;
  line-height: 15px;
  margin: 0;
  text-align: center;
}

.philosophy {
  margin: 20px 0;
}

.philosophy-text {
  font-family: "Bebas Neue", "Rockwell condensed", Impact, sans-serif;
  text-transform: uppercase;
  font-size: 40px;
  width: fit-content;
  padding-top: 30px;
  margin: 0 auto;
}
.philosophy-text + hr,
.title-season + hr {
  height: 1px;
  background: linear-gradient(to right, #38383800, #00e0ff, #38383800);
  border: none;
  margin-top: -5px;
  width: 60%;
}

.philosophy-detail {
  font-family: "Raleway Semibold", "Helvetica Semibold", "Arial semibold",
    sans-serif;
  font-size: 18px;
  font-style: italic;
}

.philosophy-logo {
  width: 74%;
  display: flex;
  margin: 0 auto;
}
.philosophy-description {
  font-family: "Raleway Medium", Helvetica, Arial, sans-serif;
  font-size: 12px;
  text-align: center;
}

.ourheroes {
  margin-bottom: 50px;
}

.padder {
  margin: 0 2em;
}

.philosophy-subtitle {
  font-family: "Bebas Neue", "Rockwell condensed", Impact, sans-serif;
  text-transform: uppercase;
  font-size: 37px;
  position: relative;
  width: 100%;
}

.philosophy-marco-text {
  position: absolute;
  width: 100%;
  margin-top: -0.2%;
  height: 43px;
  left: 0;
}

.AboutUs .title {
  font-family: "Ralewey Bold", "Helvetica Semibold", "Arial semibold",
    sans-serif;
  font-size: 19px;
  width: 100%;
}

.AboutUs .sub-title {
  font-family: "Ralewey Bold", "Helvetica Semibold", "Arial semibold",
    sans-serif;
  font-size: 16px;
  width: 100%;
  text-align: center;
}

@media screen and (min-width: 576px) {
  .hero p {
    font-size: 180px;
  }

  .hero-img {
    display: none;
  }
  .hero-img-medium {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 0;
  }

  .team-photo-img {
    display: none;
  }
  .team-photo-img-medium {
    width: 100%;
    display: flex;
    margin: -14% 0;
  }

  .aboutUs-description {
    padding: 20% 5% 1%;
  }

  .description-details {
    font-size: 21px;
    line-height: 115%;
    margin: 2% 0 16%;
  }

  .philosophy-text {
    font-size: 72px;
  }

  .philosophy-text + hr,
  .title-season + hr {
    margin-top: -10px;
    width: 60%;
  }

  .philosophy-detail {
    font-size: 34px;
    margin: 6% auto 2%;
  }

  .philosophy-logo {
    width: 75%;
  }

  .philosophy-description {
    font-size: 21px;
  }

  .AboutUs .title {
    font-family: "Ralewey Bold", "Helvetica Semibold", "Arial semibold",
      sans-serif;
    font-size: 29px;
    width: 100%;
  }

  .AboutUs .sub-title {
    font-family: "Ralewey Bold", "Helvetica Semibold", "Arial semibold",
      sans-serif;
    font-size: 27px;
    width: 100%;
    text-align: center;
  }
}

@media screen and (min-width: 768px) {
  .hero p {
    font-size: 241px;
  }

  .description-details {
    font-size: 29px;
    line-height: 115%;
    margin: 2% 0 16%;
  }

  .philosophy-text {
    font-size: 96px;
  }
  .philosophy-text + hr,
  .title-season + hr {
    width: 35%;
  }
  .philosophy-detail {
    font-size: 45px;
    width: 100%;
  }

  .philosophy-logo {
    width: 76%;
  }
  .philosophy-description {
    font-size: 29px;
  }

  .AboutUs .title {
    font-size: 40px;
  }

  .AboutUs .sub-title {
    font-size: 35px;
    text-align: center;
  }
}

@media screen and (min-width: 1024px) {
  .hero {
    padding: 10% 0% 0%;
  }

  .hero img {
    display: none;
  }

  .hero p {
    font-size: 315px;
  }

  .aboutUs-description {
    padding: 5% 22% 3%;
  }

  .container-description-details {
    position: relative;
  }

  .container-description-details::before {
    display: block;
    content: "";
    width: 111px;
    height: 76px;
    position: absolute;
    top: -5px;
    left: 0px;
    border-top: 1px solid #000;
    border-left: 1px solid #000;
  }

  .description-details::before {
    display: block;
    content: "";
    width: 111px;
    height: 76px;
    position: absolute;
    top: -14px;
    left: -14px;
    border-top: 1px solid #000;
    border-left: 1px solid #000;
  }
  .container-description-details::after {
    display: block;
    content: "";
    width: 111px;
    height: 76px;
    position: absolute;
    bottom: 52px;
    right: 0px;
    border-bottom: 1px solid #000;
    border-right: 1px solid #000;
  }

  .description-details::after {
    display: block;
    content: "";
    width: 111px;
    height: 76px;
    position: absolute;
    bottom: -14px;
    right: -14px;
    border-bottom: 1px solid #000;
    border-right: 1px solid #000;
  }

  .description-details {
    text-align: justify;
    font-size: 18px;
    margin: 2% 3% 12%;
    position: relative;
  }

  .philosophy-text {
    font-size: 75px;
  }

  .philosophy-detail {
    font-size: 25px;
    width: 57%;
    margin: 1% auto;
  }

  .philosophy-logo {
    width: 65%;
  }

  .philosophy-description {
    text-align: justify;
    font-size: 18px;
    margin: 5% 2%;
  }
  .padder {
    margin: 0 10%;
  }

  .AboutUs .sub-title {
    font-size: 35px;
    text-align: left;
  }
}

@media screen and (min-width: 1440px) {
  .container-description-details::before {
    left: 8px;
    top: -4px;
  }

  .container-description-details::after {
    right: 8px;
    bottom: 101px;
  }
}
