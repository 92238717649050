header {
  width: 80%;
  margin: auto;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px 0;
}

.logo-large {
  display: none;
}

.menu {
  display: none;
  flex-direction: column;
  width: 100%;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.menu li {
  font-family: "Bebas Neue", "Rockwell condensed", Impact, sans-serif;
  text-transform: uppercase;
  font-size: 19px;
  letter-spacing: 0.2em;
  text-align: center;
  margin: 10px 0;

  & a {
    color: white;
    text-decoration: none;
    padding: 8px 16px;
  }

  & a:hover {
    color: #00e0ff;
  }

  & a.selected {
    color: #00E0FF;
    filter: drop-shadow(0px 0px 40px rgba(0, 224, 255, 1));
    font-size: 20px;
  }
}

.menu-toggle {
  display: none;
}

.menu-icon {
  display: block;
  font-size: 26px;
  color: white;
  cursor: pointer;
}

.menu-toggle:checked+.menu-icon+.menu {
  display: flex;
}

@media screen and (min-width: 768px) {}

@media (min-width: 768px) {
  .menu {
    display: flex;
    flex-direction: row;
    width: auto;
  }

  .menu li {
    margin: 0 10px;
  }

  .menu-icon {
    display: none;
  }
}


.dropdown {
  position: relative;
  display: inline-block;
  margin-top: 0
}

.dropdown-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.dropdown-menu {
  display: block;
  margin-top: 10px;
  position: absolute;
  background-color: #000000;
  min-width: 160px;
  box-shadow: 0px 2px 25px 0px rgba(0, 224, 255, 0.5);
  z-index: 1;
  border-radius: 5px;
  right: 0;
  list-style: none;
  padding-left: 0;
}

.dropdown-menu li {
  list-style: none;
}

.dropdown-menu li a {
  color: white;
  padding: 12px 0px;
  margin: 0;
  text-decoration: none;
  display: block;
}

.nav-logo {
  margin-right: 10px;
}

.dropdown-menu hr {
  height: 1px;
  background: linear-gradient(to right, #38383800, #00e0ff, #38383800);
  border: none;
  margin:  0 auto;
  width: 60%;
}